@import './variables';

.sort {
  position: relative;
  padding-bottom: 21px;
  width: max-content;
  
  &__label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    white-space: nowrap;

    b {
      margin-right: 8px;
    }

    span {
      
      &.sort__title {
        color: $orange;
        cursor: pointer;
        user-select: none; 
        border-bottom: 1px dashed $orange;

        &:hover {
          color: darken($orange, 10%);
        }
      }
    }
  }

  &__popup {
    position: absolute;
    right: 0;
    margin-top: 15px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0;
    width: 160px;
    z-index: 2;

    ul {
      overflow: hidden;
      li {
        padding: 12px 20px;
        cursor: pointer;

        &.active,
        &:hover {
          background: rgba(254, 95, 30, 0.05);
        }

        &.active {
          font-weight: bold;
          color: $orange;
        }
      }
    }
  }
}

.icon-arrow {
  width: 25px;
  height: 14px;
  cursor: pointer;
      
  &-top {
    background-image: url('../../assets/img/arrow-top.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 6px;
    margin-left: 10px;
    margin-top: 3px;

    &.desc {
      transform: rotate(-180deg);
      margin: 3px 0 0 2px;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 0.1 ease-in-out ;
  }

  &:active {
    transform: translateY(1px);
  }
}
