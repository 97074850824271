$grid-breakpoints: (
    xxs:    340px,
    xs:     560px,
    mxs:    620px,
    sm:     860px,
    smd:    960px,
    md:     1280px,
    lg:     1480px,
) !default;


// media down
@mixin media-breakpoint-down($breakpoint) {
  @if map-has_key($grid-breakpoints, $breakpoint) {
      @media screen and (max-width: map_get($grid-breakpoints, $breakpoint )) {
          @content;
      }
  } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}