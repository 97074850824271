@import './variables';

.pizza-card {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  border: #e9e9e9 solid 2px;
  padding: 14px;
  border-radius: 35px;
}

.pizza-block {
  text-align: center;
  width: 100%;

  &__image {
    width: 235px;
    padding: 25px 0 25px 10px;

    @include media-breakpoint-down(smd) {
      width: 200px;
    }

    @include media-breakpoint-down(sm) {
      width: 230px;
    }

    @include media-breakpoint-down(mxs) {
      width: 280px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 1%;
    margin-bottom: 20px;
    height: 50px;
  }

  &__selector {
    display: flex;
    background-color: #f3f3f3;
    border-radius: 10px;
    flex-direction: column;
    padding: 6px;

    ul {
      display: flex;
      flex: 1;

      &:first-of-type {
        margin-bottom: 6px;
      }

      li {
        
        padding: 8px;
        flex: 1;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        @include noselect();
        &.active {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          cursor: auto;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

    button > .icon-plus {

      @include media-breakpoint-down(smd) {
        display: none;
      }
    }
  }

  &__price {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    white-space: nowrap;
    margin-right: 5px;
    
    @include media-breakpoint-down(smd) {
      font-size: 18px;
    }
  }
}
