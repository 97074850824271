.not-found-page {
  text-align: center;
  padding: 10px;
  margin-bottom: 105px;

  h1 {
    font-size: 32px;
  }

  span {
    font-size: 48px;
  }

  .description {
    font-size: 16px;
  }
}