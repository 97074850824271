@import './components/grid';
@import './components/variables';
@import './components/normalize';
@import './components/all';

@mixin title($size) {
  font-size: $size;
}

body {
  background-color: #fff;
  padding: 0 calc(20px - (100vw - 100%)) 0 0;
}

.svg-icon {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper {
  height: 100%;
  background-color: #fff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1480px;

  @include media-breakpoint-down(xs) {
    margin: 0px auto;
  }
}

.content {
  padding: 20px 0 40px 0;

  &__title {
    margin: 20px 0 30px 0;
    padding-left: 4px;
    display: none;

    &.small {
      @include title(24px)
    }

    &.medium {
      @include title(28px)
    }

    &.large {
      @include title(32px)
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin: 50px 0;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(mxs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(mxs) {
      display: block;
    }
  }
}

.container {
  width: $container-width;
  margin: 0 auto;

  &--cart {
    max-width: 820px;
    margin: 0 auto;

    .content__title {
      margin: 0;
    }
  }
}

.cart {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content__title {
    display: flex;
    align-items: center;
    font-size: 32px;

    svg {
      position: relative;
      top: -2px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      path {
        stroke: $black;
        stroke-width: 1.9;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray-line;
    padding-top: 30px;
    margin-top: 30px;

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 10%;

      img {
        width: 80px;
        height: 80px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;

      h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }

      p {
        font-size: 18px;
        color: #8d8d8d;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 13%;

      &-minus {
        .svg-icon {
          background-image: url('../assets/img/cart-minus.svg');
        }
        &:hover {
          .svg-icon {
            background-image: url('../assets/img/cart-minus-white.svg');
          }
        }
      }

      b {
        font-size: 22px;
      }

      &-plus {
        .svg-icon {
          background-image: url('../assets/img/cart-plus.svg');
        }
        &:hover {
          .svg-icon {
            background-image: url('../assets/img/cart-plus-white.svg');
          }
        }
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;

      b {
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0.01em;
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      .button {
        border-color: darken($color: $gray-line, $amount: 10);

        .svg-icon {
          background-image: url('../assets/img/cart-close.svg');
          transform: rotate(45deg);
        }
        &:hover {
          .svg-icon {
            background-image: url('../assets/img/cart-close-white.svg');
          }
        }
      }
      .button {
        &:hover,
        &:active {
          border-color: darken($color: $gray-line, $amount: 80);
          background-color: darken($color: $gray-line, $amount: 80);
        }
      }
    }
  }

  &__bottom {
    margin: 50px 0;

    &-details {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 22px;

        &:last-of-type {
          b {
            color: $orange;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .go-back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;

        border-color: darken($color: $gray-line, $amount: 10);

        span {
          color: darken($color: $gray-line, $amount: 20);
          font-weight: 500;
          font-weight: 600;
        }

        &:hover {
          background-color: darken($color: $gray-line, $amount: 90);
          border-color: darken($color: $gray-line, $amount: 90);

          span {
            color: $gray-line;
          }
        }

        svg {
          margin-right: 12px;
          path {
            fill: transparent;
            stroke-width: 2;
          }
        }
      }

      .pay-btn {
        font-size: 16px;
        font-weight: 600;
        width: 210px;
        padding: 16px;
      }
    }
  }

  &--empty {
    margin: 0 auto;
    width: 620px;
    text-align: center;

    h2 {
      font-size: 32px;
      margin-bottom: 22px;
    }

    p {
      font-size: 18px;
      line-height: 145.4%;
      letter-spacing: 0.01em;
      color: #777777;
    }

    icon {
      position: relative;
      top: 2px;
    }

    img {
      display: block;
      width: 300px;
      margin: 45px auto 60px;
    }

    .button--black {
      padding: 12px 0 14px;
      width: 230px;
      margin: 0 auto;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.server-error {
  margin: 100px auto;
  width: 620px;
  text-align: center;

  h2 {
    font-size: 32px;
    margin-bottom: 22px;
  }

  p {
    font-size: 18px;
    line-height: 145.4%;
    letter-spacing: 0.01em;
    color: #777777;
  }
}
