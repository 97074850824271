@import './variables';

.categories {
  max-width: 760px;
  position: relative;
  width: max-content;

  &__label {
    display: none;
  }

  &__content {
    display: flex;

    li {

      button {
        background-color: #F3F3F3;
        padding: 13px 30px;
        border-radius: 30px;
        border: 0px solid;
        margin-right: 8px;
        margin-bottom: 8px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;
        @include noselect();

        &:hover {
          background-color: darken(#f9f9f9, 5%);
        }

        &:active {
          background-color: darken(#f9f9f9, 7%);
        }

        &.active {
          background-color: #282828;
          background-color: $orange;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    &__label {
      display: flex;
      margin-top: 10px;
      white-space: nowrap;

      &__name {

        @media (max-width: 700px) {
          // display: none;
        }
      }

      .categories__title {
        margin: 0 20px 0 8px;
        color: #fe5f1e;
        cursor: pointer;
        user-select: none;
        border-bottom: 1px dashed #fe5f1e;        
      }
    }

    &__popup {
      display: none;

      &.active {
        display: block;
        position: absolute;
        right: 0;
        margin-top: 15px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        overflow: hidden;
        padding: 10px 0;
        width: 160px;
        z-index: 2;
  
        .categories__content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
      
          li {
            width: 100%;
      
            button {
              background-color: #f3f3f300;
              border-radius: 0px;
              border: 0px solid;
              padding: 12px 20px;
              width: 100%;
              cursor: pointer;
              margin: 0px;
              font-family: Nunito, Roboto, system-ui, Tahoma, sans-serif;
              font-weight: normal;
              font-size: 16px;
              text-align: left;
              @include noselect();
      
              &:hover {
                background: rgba(254, 95, 30, 0.05);
              }
  
              &.active {
                font-weight: bold;
                color: #fe5f1e;
                background: rgba(254, 95, 30, 0.05);
              }
            }
          }
        }
      }
    }
  }
}
